@import "~@ng-select/ng-select/themes/default.theme.css";

body {
  font-family: 'Trade Gothic for Nike 365';
}

#theme-loader{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #000000f7;
  z-index: 9999;
  display: none;
}

a {
  color: #0030a9;
  cursor: pointer;
}

#registerModal h1 {
  font-family: 'Futura Std';
  font-size: 30px;
  margin: 45px;
}

/* .bg-light {
  background: #000 !important;
} */

.form-check {
  font-size: 17px;
  color: #000;
}

input[type=checkbox] {
  height: 20px;
  width: 20px;
  margin-top: 2px;
}

.form-check-label {
  margin-left: 6px;
}

.alrdy-act {
  margin-top: 40px;
  font-size: 18px;
}

.alrdy-act a {
  font-family: 'Trade Gothic LT Std';
  color: #0030a9;
}

.form-check a {
  color: #0030A9;
  font-family: 'Trade Gothic LT Std';
}

.agre-dgre button {
  display: inline-block;
  width: 170px;
  vertical-align: text-bottom;
}

#termsAndConditionModal h1 {
  font-family: 'Futura Std';
  font-size: 30px;
  margin: 45px;
}

#loginModal h1 {
  font-family: 'Futura Std';
  font-size: 30px;
  margin: 45px;
}

.container {
  max-width: 1200px;
}

.navbar-light {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
}

button.clos {
  background: none;
  border: none;
  color: red;
  font-size: 30px;
  line-height: 31px;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 20px;
  text-transform: uppercase;
  margin-left: 85px;
  padding: 0;
  line-height: 44px;
  color: #818181;
  font-weight: 400;
  font-family: 'Trade Gothic LT Std';
}

.navbar-light .navbar-nav .nav-item.active a {
  color: #030303;
}

.navbar-light .navbar-nav .nav-item .nav-link:hover {
  color: #030303;
}

.navbar-expand-lg .navbar-collapse {
  position: relative;
}

.main-d {
  padding-top: 80px
}

.usr-detal {
  font-size: 24px;
}

.usr-detal a {
  color: #0030A9;
}

.btn-primary {
  background: #0030A9;
  color: #fff;
  font-size: 14px;
  padding: 10px 30px;
  width: 220px;
  border: 2px solid #0030A9;
  display: inline-block;
  margin: 15px 15px 30px;
  border-radius: 0px;
}

.btn-primary:hover {
  background: #fff;
  color: #0030A9;
  border: 2px solid #0030A9;
}

.hero-image {
  background: url("./assets/images/dash-banner.png");
  min-height: 295px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 66px;
  background-position: top;
}

.das-menu {
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  color: #1444BD;
  margin-top: -64px;
}

.das-menu a {
  color: #1444BD;
}

.das-menu a:hover {
  text-decoration: none;
}

.das-menu img {
  height: 125px;
  width: 125px;
  margin-bottom: 20px;
}

img {
  max-width: 100%;
}

.data-sta {
  font-size: 36px;
  color: #000;
  text-align: center;
  border: 1px solid #979797;
  min-height: 260px;
  padding: 26px 0 26px;
  margin: 75px 0;
  display: grid;
  align-content: space-between;
}

.data-sta span {
  color: #69BFC5;
  font-size: 60px;
  display: block;
  font-family: 'Futura';
  opacity: .9;
  text-shadow: 1px 1px #c89a9a;
}

.h3-heding {
  font-size: 36px;
  font-family: 'Trade Gothic LT Std';
}

.cls-name {
  display: block;
  font-size: 36px;
  color: #000;
  font-family: 'Futura';
}

.cls-name img {
  vertical-align: baseline;
}

.cls-name:hover {
  text-decoration: none;
}

.firsttime p {
  font-size: 20px;
}

.firsttime .form-group p a {
  font-size: 20px;
}

.navbar-light .navbar-nav.mr-auto {
  margin-left: 23%;
}

.navbar-light .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar-nav.user-menu .nav-link {
  font-size: 18px;
  text-transform: capitalize;
  margin-left: 15px;
  color: #000000;
}

.navbar-nav.user-menu {
  position: absolute;
  top: -10px;
  right: 10px;
}

.selectpicker {
  background: transparent;
  border: none;
  color: #fff;
  position: absolute;
  right: 22px;
  top: 8px;
}

.selectpicker1 {
  background: transparent;
  border: none;
  color: #fff;
  position: absolute;
  right: 0;
  top: 28px;
}

.selectpicker:focus {
  outline: none;
}

.selectpicker option {
  color: #000;
  border: none;
}

.navbar-nav.user-menu .nav-link:hover {
  color: #fff;
}

.navbar-nav.user-menu .dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  right: 0;
  min-width: 15rem;
}

.dashboard h1 {
  color: #000;
  font-size: 60px;
  font-weight: 700;
  margin-top: 30px;
  padding-bottom: 15px;
  text-align: center;
  font-family: 'Futura';
  position: relative;
  text-transform: uppercase;
}

.dashboard h2 {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  margin-top: 50px;
  padding-bottom: 15px;
  font-family: 'Futura';
  position: relative;
  text-transform: uppercase;
}

.dashboard h1 a {
  position: absolute;
  left: 0px;
  color: #C9C9C9;
  font-size: 22px;
  font-family: 'Trade Gothic for Nike 365';
  font-weight: 600;
  text-transform: capitalize;
}

.dashboard h1 a i {
  vertical-align: middle;
  font-size: 20px;
}

.field-add {
  font-size: 20px;
  color: #C9C9C9;
  font-weight: 600;
}

.permision .develp-stage.table {
  border: none;
}

.permision .develp-stage.table thead th {
  background: #fff;
  color: #000;
  border: 1px solid #979797;
  font-weight: 600;
  font-size: 15px;
  padding: 25px 10px 10px;
  position: inherit;
}

.permision .develp-stage.table tr td {
  border: 1px solid #979797;
  font-size: 17px;
  padding: 0;
  font-weight: 600;
  line-height: 130px;
}

.permision .develp-stage.table tr td textarea {
  border: none;
  min-height: 120px;
  line-height: normal;
  font-size: 16px;
  resize: none;
}

.permision .develp-stage.table tr td textarea:focus {
  outline: none;
}

.permision .develp-stage.table tbody tr:hover {
  background-color: transparent;
}

.about p.h4 {
  line-height: 32px;
}

.abt-imag {
  /* width: 1000px; */
  width: 38%;
}

.funkyradio .funkyradio-primary {
  clear: both;
  overflow: hidden;
  display: inline-block;
  width: 240px;
  margin-right: 20px;
}

/* .funkyradio label {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #D1D3D4;
  font-weight: normal;
} */

.funkyradio input[type="checkbox"]:empty {
  display: none;
}

.funkyradio input[type="checkbox"]:empty~label {
  position: relative;
  line-height: 2.5em;
  text-indent: 3.25em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  color: #000;
  border-radius: 4px;
}

.funkyradio input[type="checkbox"]:empty~label:before {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  content: '';
  width: 2.5em;
  background: #D1D3D4;
  border-radius: 3px 0 0 3px;
}

.funkyradio input[type="checkbox"]:empty~label:after {
  background-color: #fff;
  position: absolute;
  top: 10px;
  left: 10px;
  content: '';
  height: 14px;
  width: 14px;
  border-radius: 2px;
}

.funkyradio input[type="checkbox"]:hover:not(:checked)~label {
  color: #888;
}

.funkyradio input[type="checkbox"]:hover:not(:checked)~label:before {
  content: '\2714';
  text-indent: .9em;
  color: #C2C2C2;
}

.funkyradio input[type="checkbox"]:checked~label {
  border-color: #0030a9;
}

.funkyradio input[type="checkbox"]:checked~label:before {
  content: '\2714';
  text-indent: .9em;
  color: #333;
  background-color: #ccc;
}

.funkyradio input[type="checkbox"]:focus~label:before {
  box-shadow: 0 0 0 3px #999;
}

.funkyradio-primary input[type="checkbox"]:checked~label:before {
  color: #fff;
  background-color: #0030A9;
}

.funkyradio-primary input[type="checkbox"]:checked~label:after {
  background: url(./assets/images/check.svg);
  position: absolute;
  top: 10px;
  left: 10px;
  content: '';
  height: 14px;
  width: 14px;
  background-size: contain;
}

.list-stl {
  list-style-position: inside;
  padding: 0;
  font-size: 24px;
}

.abt-logo {
  max-height: 200px;
}

.loginn {
  padding-top: 0;
}

.loginn h1 {
  color: #000000;
  font-size: 36px;
  font-weight: 600;
  margin-top: 50px;
  border-bottom: none;
  padding-bottom: 15px;
  text-transform: uppercase;
}

.loginn .form-control {
  border-radius: 0;
  height: 55px;
  font-size: 14px;
  color: #000;
}

.loginn .form-group label {
  color: #000;
  font-size: 18px;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.loginn .form-group a {
  font-size: 12px;
}

.forgt-pass .main-btn-cncel {
  width: 100%;
}


.d-section {
  border: 2px solid #979797;
  margin: 10px;
  padding: 40px 0;
  min-height: 250px;
}

.d-section h2 {
  color: #4b90de;
  font-size: 80px;
  font-weight: 800;
}

.d-section p {
  color: #1d333e;
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
}

.d-section p a {
  color: #1d333e;
}

.d-section p span {
  color: #1d333e;
  font-size: 20px;
  font-weight: 400;
  display: block;
}

.myform {
  position: relative;
  display: -ms-flexbox;
  padding: 4rem 3rem;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #f7f7f7;
  background-clip: padding-box;
  outline: 0;
  width: 500px;
  box-shadow: 0px 5px 20px rgb(0 0 0 / 25%);
  border: 1px solid #0F5475;
  margin-top: 50px;
}

#custom-select select {
  /* Hide default arrow */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  /* Style the dropdown box */
  width: 100%;
  padding: 0 10px;
}

#custom-select {
  position: relative;
  max-width: 100%;
  /* optional */
}

#custom-select select,
#custom-select::after {
  height: 38px;
  border: 1px solid #979797;
  background: transparent;
  z-index: 99;
  position: relative;
  font-size: 16px;
}

#custom-select::after {
  content: "\25bc";
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 10px;
  line-height: 38px;
  background: #7e7e7e;
  color: #fff;
  border: none;
  z-index: 9;
  height: 38px;
}

.permision .custom-radio {
  display: inline-block;
  margin: 0 20px;
}

.permision .custom-radio label {
  font-size: 20px;
  margin-left: 10px;
  margin-top: 3px;
  padding-left: 10px;
  line-height: 33px;
}

.permision .custom-radio .custom-control-label::before {
  width: 27px;
  height: 27px;
}

.permision .custom-radio .custom-control-label::after {
  width: 27px;
  height: 26px;
}

.permision .table td {
  border: none;
  font-size: 20px;
  border: 1px solid #979797;
  border-left: none;
  border-right: none;
}

.permision .table td:first-child {
  border-left: 1px solid #979797;
}

.permision .table td:last-child {
  border-right: 1px solid #979797;
}

.permision .table td.arow-dwn a {
  color: #4A4A4A;
}

.permision .table td.hiddenRow {
  border-bottom: 1px solid #E8EBEC;
}

.permision .table thead th {
  border-bottom: none;
  background: #fff;
  color: #000;
  font-weight: 400;
  font-size: 18px;
  border: none;
}

.permision .table tr td.arow-dwn img {
  -ms-transform: rotate(0deg);
  /* IE 9 */
  transform: rotate(0deg);
}

.permision .table tr.collapsed td.arow-dwn img {
  -ms-transform: rotate(180deg);
  /* IE 9 */
  transform: rotate(180deg);
}

.permision .permisn-usr p {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 20px;
}

.permision .permisn-usr p strong {
  font-weight: bold;
}

.permision .permisn-usr ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.permision .permisn-usr ul li {
  display: inline-block;
}

.permision .permisn-usr ul li label {
  font-size: 20px;
  font-weight: 300;
}

.permisn-usr .nav-tabs {
  border: none;
}

.permisn-usr .nav-tabs .custom-radio {
  margin: 0 30px 0 0;
}

.permisn-usr .tab-content .tab-pane ul li {
  display: block;
  margin: 10px;
  padding-left: 13px;
}

.main-btn {
  background: #0030A9;
  color: #fff;
  font-size: 18px;
  padding: 10px 30px;
  font-weight: 500;
  width: 260px;
  border: 2px solid #0030A9;
  display: inline-block;
  margin: 15px 15px 30px;
}

.main-btn:hover {
  background: #fff;
  color: #0030A9;
  border: 2px solid #0030A9;
  text-decoration: none;
}

.html-lnk {
  display: inline-block;
  width: 300px;
  margin-left: 30px;
}

.disable-btn {
  background: #858585;
  color: #fff;
  font-size: 18px;
  padding: 10px 30px;
  font-weight: 500;
  width: 260px;
  border: 2px solid #858585;
  display: inline-block;
  margin: 15px 15px 30px;
}

.disable-btn:hover {
  background: #fff;
  color: #858585;
  border: 2px solid #858585;
  text-decoration: none;
}

.disable-btn-cn {
  background: #fff;
  color: #858585;
  font-size: 18px;
  padding: 10px 30px;
  font-weight: 500;
  width: 270px;
  border: 2px solid #858585;
  display: inline-block;
  margin: 15px 15px 30px;
}

.disable-btn-cn:hover {
  background: #858585;
  color: #fff;
  border: 2px solid #858585;
  text-decoration: none;

}

.main-btn-cncel {
  background: #fff;
  color: #0030A9;
  font-size: 18px;
  padding: 10px 30px;
  font-weight: 500;
  border: 2px solid #0030A9;
  width: 270px;
  display: inline-block;
  margin: 15px 15px 30px;
}

.main-btn-cncel:hover {
  background: #0030A9;
  color: #fff;
  border: 2px solid #0030A9;
  text-decoration: none;
}

/* .task-1 h1 {
  color: #69BFC5;
}

.task-1 h1 span {
  color: #9981BB;
}

.task-1 h2 {
  color: #9981BB;
  font-size: 60px;
  font-family: 'Trade Gothic for Nike 365';
} */

.task-1 a.lft-rgt {
  color: #C9C9C9;
  font-size: 22px;
  font-family: 'Trade Gothic for Nike 365';
  font-weight: 600;
}

.traning-vw .tsk-hd {
  font-size: 60px;
  font-family: 'Futura';
}

.traning-vw .tsk-hd img {
  vertical-align: middle;
  margin-bottom: 20px;
}

.ply-phse {
  font-family: 'Futura';
  font-size: 60px;
  color: #69BFC5;
}

.ply-phse img {
  vertical-align: middle;
  margin-bottom: 25px;
}

.ply-phse:hover {
  color: #69BFC5;
}

.ply-phse.col-mr {
  color: #742B8B;
}

.ply-phse.col-rd {
  color: #DD5643;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: #0030A9;
}

.tasks .main-btn-cncel {
  font-size: 15px;
  padding: 10px 10px;
}

.tasks h3 {
  font-size: 25px;
}

.permisn-usr .nav-tabs .custom-radio label {
  line-height: 35px;
}

.grey-col {
  background: #eaebed;
}

.serch-sec {
  display: flex;
  justify-content: space-between;
  padding-left: 60px;
}

.serch-sec .form-control {
  border-color: #0030A9;
  position: relative;
  border-radius: 3px;
}

.serch-sec .src-icn {
  position: absolute;
  right: 10px;
  top: 10px;
  background: url(./assets/images/search.svg);
  height: 20px;
  width: 19.3px;
  z-index: 9;
}

.serch-sec a {
  font-size: 14px;
  color: #0030A9;
  width: 100px;
}

.serch-sec .btn-outline-primary {
  border-color: #0030A9;
  color: #0030A9;
  width: 100px;
  margin-left: 20px;
  font-weight: 500;
  -moz-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.serch-sec .btn-outline-primary:hover {
  color: #fff;
  background-color: #0030A9;
}

.serch-sec .btn-outline-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #0030A9;
  border-color: #0030A9;
}

.sys-app-details p {
  margin: 32px 20px;
}

.app-color {
  height: 45px;
  width: 45px;
  background: #1C3440;
  display: inline-block;
  margin: 0px 10px 0px 0px;
  vertical-align: middle;
}

.form-control {
  border: 1px solid #979797;
  border-radius: 0;
}

.sys-app-details label {
  margin-top: 10px;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.nav-tabs {
  border: none;
  display: block;
}

.nav-tabs li {
  display: block;
  font-size: 14px;
  font-weight: 300;
}

.nav-tabs li.active {
  background: #E8EBEC;
}

.nav-tabs li:hover {
  background: #E8EBEC;
}

.nav-tabs li a {
  color: #000;
  padding: 10px 15px 10px 25px;
  display: block;
}

.nav-tabs li a:hover {
  text-decoration: none;
}

.rounds .accordion-group {
  background: #E8EBEC;
  margin-bottom: 15px;
  padding: 12px 15px;
}

.rounds .accordion-group p {
  margin-bottom: 10px;
}

.rounds .accordion-heading a {
  color: #000;
  position: relative;
  font-size: 18px;
  font-weight: 600;
  display: block;
}

.rounds .accordion-toggle:after {
  background: url(./assets/images/arrow-dow.svg);
  content: '';

  height: 10px;
  width: 10px;
  display: block;
  position: absolute;
  right: 1px;
  top: 1px;
  z-index: 999999;
}

.permision.judges p {
  font-size: 14px;
  margin-bottom: 12px;
}

.judges .main-btn-cncel {
  max-width: 170px;
}

.wnr-bg {
  background: #E8EBEC;
}

.brackets {
  display: block;
}

.bracket {
  width: 24%;
  display: inline-block;
  vertical-align: top;
}

.bracket-box {
  border: 2px solid #1C3440;
  width: auto;
  min-height: 100px;
  font-size: 18px;
  color: #000;
  padding: 10px 0;
  margin: 20px 10px;
}

.bracket-box hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.name-sr {
  display: inline-block;
  width: 65%;
  padding-left: 12px;
}

.name-sr p {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  margin-bottom: 0;
}

.name-sr p:firt-child {
  width: 20px;
}

.name-sr img {
  max-width: 40px;
  display: inline-block;
  vertical-align: middle;
  margin: 0px 10px;
}

.country-flag {
  width: 30%;
  text-align: right;
  display: inline-block;
}

.country-flag img {
  max-width: 35px;
}

.margin30 {
  margin-top: 97px;
}

.margin50 {
  margin-top: 165px;
}

.margin75 {
  margin-top: 230px;
}

.margin140 {
  margin-top: 430px;
}

.margin160 {
  margin-top: 490px;
}

.viw-tournament .card-body {
  overflow-x: auto;
}

.viw-tournament .brackets {
  overflow-x: scroll;
  width: 1430px;
}

.form-group {
  margin-bottom: 2rem;
}

.ad-anotr {
  font-size: 24px;
  color: #C9C9C9 !important;
  font-weight: 600;
}

.ad-anotr:hover {
  text-decoration: none;
}

/* Rajaram css Start */

.Configuration h5 {
  font-size: 20px;
}

.Configuration label.custom-control-label {
  font-size: 17px;
  font-weight: 100;
  text-transform: capitalize;
  margin-top: 9px;
  line-height: 44px;
}

.Configuration .custom-radio {
  padding-bottom: 15px;
  margin: 0px 0px 0px 5px;
}

.Configuration .custom-radio .custom-control-label::before {
  width: 22px;
  height: 22px;
}

.Configuration .custom-radio .custom-control-label::after {
  width: 22px;
  height: 22px;
}

.Configuration .custom-control-label::after {
  top: 0.69rem;
}

.Configuration .custom-control-label::before {
  top: 0.70rem;
}

.competitored span {
  padding: 10px;
}

.form-group.competitored label {
  display: block;
}

.competitored input {
  display: inline-block;
  width: 90%;
}

i.fa.fa-chevron-down {
  float: right;
  padding: 7px;
  border-radius: 22px;
  color: black;
  font-size: 14px;
  transform: rotate(180deg);
}

button.btn.btn-link.collapsed.btn-block.text-left i.fa.fa-chevron-down {
  transform: rotate(0deg);
}

.final-sec .card {
  margin-bottom: 15px;
  border: none;
}

.final-sec .card-body {
  background: #E7E9EB;
}

.final-sec .card-header {
  background: #E7E9EB;
  border: none;
  padding: 3px;
  border-radius: 0;
}

.final-sec button.btn.btn-link.btn-block.text-left {
  color: black;
  font-size: 18px;
  font-weight: 600;
}

.final-sec button.btn.btn-link.btn-block.text-left:hover {
  text-decoration: none;
}

.final-sec table {
  width: 100%;
  border-collapse: collapse;
  line-height: 32px;
}

.final-sec table img {
  padding: 5px;
  width: 45px;
}

.final-sec label {
  display: block;
  font-weight: 200;
  font-size: 18px;
}

.final-sec div#custom-select {
  display: inline-block;
  width: 70%;
  margin-left: 35px;
  margin-top: 15px;
  font-size: 16px;
}

.final-sec table th {
  font-size: 14px;
  color: #999999;
  font-weight: 200;
  padding: 0px 10px;
}

.final-sec h5 {
  font-size: 18px;
  font-weight: 600;
}

.form-group.tours.row {
  padding: 0px 15px;
}

.final-sec tr {
  border: 1px solid #dedede;
}

.final-sec td {
  padding: 0px 10px;
  font-weight: 600;
  color: #333333;
  font-size: 16px;
}

td.bryjars {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  display: block;
  border: 1px solid #979797;
  line-height: 40px;
  border-radius: 3px;
}

td.fagerlis {
  display: block;
  border: 1px solid #979797;
  line-height: 40px;
  border-radius: 3px;
  margin: 10px;
  text-align: center;
}


.ngb-dp-month:first-child .ngb-dp-week {
  display: flex;
  justify-content: space-between;
}

.ngb-dp-month {
  width: 100%;
}

/* Rajaram css End */

.desknone {
  display: none;
}

.sec-menu {
  display: block;
  margin: 25px auto;
  text-align: center;
}

.sec-menu ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

.sec-menu li {
  display: inline-block;
}

.sec-menu button {
  display: inline-block;
  background: #fff;
  color: #000;
  padding: 5px 15px;
  width: 174px;
  font-size: 18px;
  text-transform: uppercase;
  border: 2px solid #000;
  text-decoration: none;
  margin: 5px;
}

.sec-menu button:hover {
  border: 2px solid #000;
  background: #000;
  color: #fff;
}

.sec-menu button.active {
  border: 2px solid #000;
  background: #000;
  color: #fff;
}

.sec-menu select {
  display: none;
}

.sec-menu-slct:after {
  display: none;
}

.imge-blbr {
  border: 1px solid #000;
  height: 200px;
}

.search-results {
  height: 200px;
  overflow: scroll;
}

.error-list {
  border: 1px solid red;
}

.task-detail-btns {
  color: #80808096 !important;
  font-weight: bold;
  cursor: pointer;
}

.row-clickable {
  cursor: pointer;
}

/* editor styling */
.editor {
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.editor .NgxEditor__MenuBar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.editor .NgxEditor {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: none;
}

.editor .CodeMirror {
  border: 1px solid #eee;
  height: auto;
  margin-bottom: 0.7rem;
}

.editor .CodeMirror pre {
  white-space: pre !important;
}



/* rotate */
.rotate {
  -moz-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.rotate.down {
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}


/* modal */


.modal-dialog {
  max-width: 75%;
  margin: 30px auto;
}

.modal-body {
  position: relative;
  padding: 0px;
}

.close {
  position: absolute;
  right: -39px;
  top: 0;
  z-index: 999;
  font-size: 2rem;
  font-weight: normal;
  color: #fff;
  opacity: 1;
  background: red;
  padding: 6px 10px 13px 10px;
  line-height: 20px;
}

.play-btn-container {
  position: relative;
  z-index: 0;
}

.play-btn {
  position: absolute;
  /* top: 50%;
  left: 45%; */
  z-index: 99;
  top: 36%;
    left: 46%;
    height: 80px;
    width: 80px;
}

.highlight-row {
  background-color: #dcdcef;
}

.custom-file-upload {
  margin-top: 12px;
  text-align: center;
}

.custom-file-upload input[type="file"] {
  display: none;
}

.custom-file-upload .custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.custom-file-upload i.fa.fa-cloud-upload {
  color: #0b33a1;
}

.body-margin {
  margin: 0px 70px;
}


@media (max-width: 960px) {
  .sec-menu {
    width: 90%;
  }

  .sec-menu ul {
    display: none;
  }

  .sec-menu-slct {
    position: relative;
  }

  .sec-menu-slct select {
    height: 40px;
    display: block;
    width: 100%;
    border-color: #979797;
    position: relative;
    z-index: 99;
    background: transparent;
  }

  .sec-menu-slct:after {
    content: "\25bc";
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 10px;
    line-height: 38px;
    background: #7e7e7e;
    color: #fff;
    border: none;
    height: 39px;
    width: 38px;
    z-index: 9;
    display: block;
  }
}

.ases-dwnd {
  font-size: 16px;
  padding: 10px 20px;
}

.rotate-arow {
  transform: rotate(180deg);
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {

  /* Force table to not be like tables anymore */
  table.responsive,
  .responsive thead,
  .responsive tbody,
  .responsive th,
  .responsive td,
  .responsive tr {
    display: block !important;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .responsive thead tr {
    position: absolute !important;
    top: -9999px;
    left: -9999px;
  }

  .responsive tr {
    border: 1px solid #ccc;
  }

  .responsive td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee !important;
    position: relative !important;
    padding-left: 25% !important;
  }

  .responsive td:before {
    /* Now like a table header */
    position: absolute !important;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap !important;
    font-weight: 500 !important;
  }

  /*
	Label the data
	*/
  .responsive td:before {
    content: attr(data-table-header) !important;
  }
}

@media screen and (max-width: 1600px) {
  .permision .develp-stage.table thead th {
    font-size: 15px;
  }

  .myform {
    padding: 3rem;
  }

  .navbar-light .navbar-nav.mr-auto {
    margin-left: 12%;
  }

}

@media screen and (max-width: 1400px) {
  .navbar-light .navbar-nav.mr-auto {
    margin-left: 8%;
  }

}


@media screen and (max-width: 1115px) {
  .navbar-light .navbar-nav .nav-link {
    margin-left: 50px;
  }

  .d-section p {
    font-size: 28px;
  }

  .navbar-nav.user-menu .nav-link {
    margin-left: 15px;
  }

  .navbar-light .navbar-nav.mr-auto {
    margin-left: 5.5%;
  }

  .sec-menu a {
    width: 165px;
  }

  .multi-user #custom-select {
    width: 153px;
  }

  .main-btn-cncel {
    padding: 10px 20px;
  }

}


@media screen and (max-width: 991px) {
  .navbar-light .navbar-toggler {
    /* display: none; */
    background-color: #ffffff;
  }

  .navbar-collapse {
    display: block;
    flex-basis: auto;
  }

  .navbar {
    height: 100px;
  }

  .navbar-light .navbar-nav .nav-link {
    margin-left: 28px;
  }

  .navbar-collapse .navbar-nav {
    flex-direction: unset;
  }

  .navbar-collapse .navbar-nav.user-menu {
    position: relative;
    top: 0px;
    display: block;
    right: 0px;
    text-align: left;
  }

  .navbar-collapse .navbar-nav.user-menu li {
    display: block;
  }

  .navbar-collapse .navbar-nav.user-menu li .nav-link {
    margin-left: 0 !important;
    margin-right: 0;
  }

  .navbar-collapse .navbar-nav.user-menu li:nth-child(2) .nav-link {
    font-size: 0px;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
  }

  .navbar-collapse .navbar-nav.user-menu .dropdown .nav-link {
    font-size: 0px;
    margin: 0;

  }

  .navbar-collapse .nav-link {
    display: inline-block;
  }

  .das-menu img {
    height: 80px;
    width: 80px;
    margin-bottom: 10px;
  }

  .das-menu {
    font-size: 18px;
  }

  .mdnone {
    display: none;
  }

  .sec-menu-slct select {
    width: 104%;
  }

  .sec-menu-slct {
    overflow: hidden;
  }

  .qst-mbl {
    width: 52% !important;
    display: inline-block;
    padding-left: 28px;
  }

  .ans-mbl {
    width: 45% !important;
    display: inline-block;
  }

}

.desktop-view{
  display:block;
}

.mobile-view{
  display:none;
}


@media screen and (max-width: 992px) {
  .modal-dialog { 
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}
  .desktop-view{
    display:none !important;
  }

  .mobile-view{
    display:block;
  }

  .body-margin {
    margin: auto !important;
  }

  .cls-name img {
    height: 15px;
  }

  .sys-app-details p {
    font-size: 14px;
  }

  .container [_ngcontent-seu-c285] {
    width: auto;
    margin: 20px auto 10px;
  }

  .navbar-light .navbar-nav.mr-auto {
    /* position: absolute; */
    bottom: 0;
    left: 10px;
    margin-left: 0;
    display: block;
  }

  .navbar-light .navbar-nav.mr-auto li:nth-child(3) a {
    width: 44px;
  }

  .navbar-light .navbar-nav.mr-auto li:nth-child(4) a {
    width: 54px;
  }

  /* .navbar-light .navbar-brand {
    margin-top: -35px;
  } */

  /* .navbar-light .navbar-brand img {
    height: 30px !important;
  } */

  .navbar-expand-lg .navbar-collapse {
    position: absolute;
    left: 0;
    top: 80px;
    width: 100%;
    background: #000;
    height: 100vh;
    padding-left: 12px;
  }

  .selectpicker {
    right: 20px;
    top: 20px;
  }

  .navbar-light .navbar-nav .nav-link {
    font-size: 15px;
    margin-left: 2px;
    margin-right: 28px;
    line-height: 34px;
    margin-top: 10px;
  }

  .selectpicker1 {
    top: 25px;
  }

  .navbar-collapse .navbar-nav.user-menu li .nav-link {
    margin-right: 0;
    margin-top: 2px;
  }

  .navbar {
    height: 80px;
    padding-bottom: 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
  }

  .dashboard .row.m-5 {
    margin: 20px 0 !important;
  }

  .dashboard .row.m-5 .col-md-4 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .dashboard h1 {
    font-size: 25px;
    margin: 0;
    border-bottom: none;
    padding-bottom: 10px;
    text-align: center;
    margin-top: 15px;
  }

  .dashboard h2 {
    font-size: 25px;
    letter-spacing: 0;
  }

  .tasks h3 {
    font-size: 20px;
  }

  .main-d {
    padding-top: 80px !important;
  }

  .h3-heding {
    font-size: 20px;
  }

  .list-stl {
    font-size: 18px;
  }

  .about p.h4 {
    line-height: 19px;
    font-size: 14px;
  }

  .abt-logo {
    max-height: 90px;
  }

  .d-section {
    text-align: left;
    min-height: inherit;
    padding: 10px 20px;
    border-width: 1px;
    margin: 10px 15px;
  }

  .d-section h2 {
    display: inline-block;
    font-size: 60px;
    margin-right: 20px;
    vertical-align: middle;
  }

  .d-section p {
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
  }

  .d-section p span {
    font-size: 14px;
  }

  .main-btn,
  .main-btn-cncel,
  .disable-btn {
    width: 80%;
    margin: 15px 0;
  }

  .serch-sec .form-control {
    width: 100%;
  }

  .serch-sec .btn-outline-primary {
    border-color: transparent;
    color: #0030A9;
    width: auto;
    margin-left: 0;
    box-shadow: none;
    position: absolute;
    right: 10px;
    font-size: 0;
  }

  .serch-sec .btn-outline-primary:hover {
    color: #0030A9;
    background: transparent;
  }

  .serch-sec .btn-outline-primary:not(:disabled):not(.disabled):active {
    color: #0030A9;
    background-color: transparent;
    border-color: transparent;
    outline: none;
    box-shadow: none;
  }

  .serch-sec .btn-outline-primary:before {
    position: absolute;
    content: 'Search';
    font-size: 18px;
    left: -30px;
    top: 5px;
  }

  .permision .mb-5 {
    margin-bottom: 20px !important;
  }

  .permision .mt-5 {
    margin-top: 20px !important;
  }

  .permision .table thead th {
    font-size: 16px;
    padding-left: 0;
  }

  .permision .table td {
    font-size: 16px;
    color: #000;
  }

  .permision .custom-radio label {
    font-size: 14px;
    margin-top: 11px;
  }

  .permision .custom-radio {
    font-size: 14px;
    margin: 0px 15px;
  }

  .mobil-mod .modal-dialog {
    margin: 0;
    border: none;
  }

  .mobil-mod .modal-dialog .modal-content {
    padding-top: 90px;
  }

  .mobil-mod.modal {
    margin-top: 0px;
    padding-top: 0px;
  }

  .modal-backdrop.fade {
    opacity: 0;
  }

  .mobil-mod .modal-header {
    border: none;
  }

  .mobil-mod .modal-header .close {
    padding: 0;
    margin: 0;
    font-size: 12px;
    color: #4A90E2;
    opacity: inherit;
    font-weight: 400;
  }

  .mobil-mod .modal-dialog .modal-body .permisn-usr img {
    max-width: 70px;
  }

  .permisn-usr .pt-5 {
    padding-top: 20px !important;
  }

  .permisn-usr .p-4 {
    padding: 0 !important;
  }

  .permisn-usr .nav-tabs .custom-radio label {
    line-height: normal;
  }

  .permisn-usr .nav-tabs .custom-radio {
    margin: 20px 30px 0 0;
    display: block;
  }

  .permision .table td.arow-dwn {
    text-align: right;
  }

  .judges .mobil-mod .modal-dialog .modal-body .permisn-usr img {
    max-width: 100%;
  }

  .judges .main-btn-cncel {
    max-width: 160px;
    padding: 10px;
  }

  .judges .permisn-usr .mb-5 {
    padding: 0px 15px;
  }

  .mobil-row {
    padding: 0 15px;
  }

  .mobil-row h6 {
    text-align: left;
  }

  #accordion-style-1 {
    width: 110%;
    margin-left: -5%;
    padding: 0;
  }

  #accordion-style-1 .container {
    padding-right: 0;
  }

  .sys-app-details {
    overflow: hidden;
  }

  .competitored input {
    width: 100%;
  }

  .final-sec div#custom-select {
    width: 84%;
  }

  .main-d.loginn {
    padding: 0 !important;
  }

  .myform {
    padding: 0;
    box-shadow: none;
    width: auto;
    border: none;
  }

  .loginn .logo img {
    z-index: 9999;
    position: relative;
    margin: 15px;
    width: 180px;
    margin-top: 50px;
  }

  .loginn .logo {
    position: relative;
  }

  .loginn .form-control {
    height: 40px;
  }

  .loginn .form-group {
    width: 90%;
    margin: 0 auto;
  }

  .final-sec td {
    font-size: 12px;
    font-weight: 400;
  }

  .final-sec tr {
    display: flex;
    justify-content: space-between;
  }

  .brackets {
    display: block;
    width: 1300px;
    overflow: auto;
  }

  .bracket-box {
    max-width: 100%;
  }

  .bracket {
    width: 24%;
    display: inline-block;
  }

  .for-mob {
    width: 33% !important;
    display: inline-block;
    margin-bottom: 20px;
  }

  .for-mob a {
    font-size: 16px;
  }

  .das-menu {
    margin-top: -37px;
  }

  .das-menu img {
    height: 65px;
    width: 65px;
    margin-bottom: 5px;
  }

  .hero-image {
    min-height: 150px; 
    margin-top: 80px;
  }

  .data-sta {
    font-size: 28px;
    min-height: 230px;
    padding: 20px;
    width: 90%;
    margin: 30px auto 30px;
  }

  .data-sta br {
    display: none;
  }

  .td100 tr {
    margin-bottom: 25px;
    display: block;
  }

  .td100 th {
    font-size: 18px !important;
  }

  .td100 td {
    width: 100% !important;
    display: block;
    padding: .75rem 0 0 0 !important;
    border: none !important;
  }

  .ad-anotr {
    font-size: 15px;
  }

  .cls-name {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .ply-phse img {
    max-height: 13px;
    vertical-align: baseline;
    margin-bottom: 0;
  }

  .task-1 img {
    max-width: 100%
  }

  .task-1 a.lft-rgt {
    font-size: 13px;
    margin-top: 0px;
  }

  .task-1 .lft-rgt+.main-btn {
    width: 50%;
    font-size: 16px;
    padding: 5px 10px;
    margin-top: 20px !important;
  }

  .Configuration {
    overflow: auto;
    text-align: center;
    margin-bottom: 0 !important;
  }

  .td-wdt25 {
    width: 25% !important;
    display: inline-block;
  }

  .td-wdt100 {
    width: 100% !important;
    display: block;
    padding-bottom: 0 !important;
  }

  .desknone {
    display: block;

  }

  label.desknone {
    font-size: 14px !important;
    margin: 0;
  }

  .pl-0mobil {
    padding-left: 0 !important;
  }

  .mob-text-left {
    text-align: left !important;
  }

  .mob-text-center {
    text-align: center !important;
  }

  .mob-text-left h3 {
    font-size: 20px;
  }

  .mob-text-left h3 br {
    display: none;
  }

  .dashboard h1 a {
    font-size: 16px !important;
  }

  .usr-detal {
    font-size: 20px;
    margin-bottom: 0;
  }

  .traning-vw .tsk-hd {
    font-size: 25px;
    margin-bottom: 12px;
  }

  .traning-vw .tsk-hd img {
    max-height: 15px;
    vertical-align: baseline;
    margin-bottom: 0;
  }

  .html-lnk {
    margin-left: 0px;
  }

  .imageuploadify .imageuploadify-images-list i {
    display: none !important;
  }

  .imageuploadify {
    min-height: 140px !important;
  }

  .imageuploadify .imageuploadify-images-list {
    padding-top: 35px;
  }

  .serch-sec {
    padding-left: 0;
  }

  .play-btn {
    top: 50%;
    left: 50%;
    height: 60px;
    width: 60px;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  /* .ng-dropdown-panel {
    position: inherit !important;
  } */

  .form-group {
    margin-bottom: 1rem;
}
.html-lnk {
  width: 100%;
}
 
}

.icon-block {
  background: #1444BD;
  width: 125px;
  height: 125px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 100%;
  margin-bottom: 1rem;
  color: white;
  font-size: 3.4rem;
  box-shadow: 0px 0px 2px 3px;
}


@media screen and (max-width: 600px)  {
  .icon-block { 
    width: 90px;
    height: 90px; 
    font-size: 30px; 
    margin-bottom: 0;
}
.for-mob { 
    margin-bottom: 0px;
}

.data-sta {
  font-size: 20px;
  min-height: auto;
}
}

.object-or-iframe-element {
  z-index: 1000;
}